<template>
  <evalmee-chip
    :x-small="xSmall"
    :small="!xSmall"
    color="blue"
    icon="mdi-timer-plus-outline"
    v-tooltip="timeAccommodation.name"
  >
    {{ label }}
  </evalmee-chip>
</template>
<script>
import EvalmeeChip from "@/components/shared/evalmee_chip.vue"

export default {
  name: "TimeAccommodationChip",
  components: { EvalmeeChip },
  props: {
    timeAccommodation: {
      type: Object,
      required: true,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    label() {
      return `${this.timeAccommodation.nominator}/${this.timeAccommodation.denominator}`
    },
  },
}
</script>

